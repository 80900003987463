import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { isInternalURL, toBackendLang, flattenToAppURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import SecondTierNavigation from './SecondTierNavigation';
import icon from '@plone/volto/icons/down-key.svg';
import icon2 from '@plone/volto/icons/up-key.svg';

import { Image } from 'semantic-ui-react';

const NavItem = ({ item, lang, depth, mobile, close }) => {
  const { settings } = config;
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    /*
    console.log('Close:', close);
    console.log('Mobile:', mobile);
    console.log('Item has no items:', !item.items);
    console.log('Item items length:', item.items.length);
    console.log('Result:', mobile && close && (!item.items || item.items.length == 0));
    */
    if ( mobile && close && (!item.items || item.items.length == 0)) {
      close();
    }
    setIsClicked(!isClicked);
  };

  if (isInternalURL(item['@id']) || item['@id'] === '') {
    return (
      <div
        key={item.url}
        className="nav-item"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {mobile && item.items && item.items.length > 0 ?
          <>
            <div
              className={isClicked ? "item" : "item active"}
              onClick={handleClick}
            >
              {item.title}
              {item.items && item.items.length > 0 &&

                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  xmlns="http://www.w3.org/2000/svg">
                  {isClicked ?
                    <path fillRule="evenodd" d="M26.707 21.293L25.293 22.707 18 15.414 10.707 22.707 9.293 21.293 18 12.586z" />
                    :
                    <path
                      fillRule="evenodd"
                      d="M9.293 14.707L10.707 13.293 18 20.586 25.293 13.293 26.707 14.707 18 23.414z" />}
                </svg>
              }
            </div>
            {isClicked && item.items && item.items.length > 0 && <SecondTierNavigation items={item.items} depth={depth + 1} mobile={mobile} close={close} />}

          </> : <>
            <NavLink
              to={flattenToAppURL(item['@id']) === '' ? '/' : flattenToAppURL(item['@id'])}
              className={isClicked ? "item" : "item active"}
              activeClassName="active"
              exact={
                settings.isMultilingual
                  ? flattenToAppURL(item['@id']) === `/${toBackendLang(lang)}`
                  : flattenToAppURL(item['@id']) === ''
              }
              onClick={handleClick}
            >
              {item.title}
              {item.items && item.items.length > 0 &&
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    d="M9.293 14.707L10.707 13.293 18 20.586 25.293 13.293 26.707 14.707 18 23.414z"
                  />
                </svg>
              }
            </NavLink>

            {/*isHovered && item.items && item.items.length > 0 && <SecondTierNavigation items={item.items} />*/}
            { isHovered && item.items && item.items.length > 0 && <SecondTierNavigation items={item.items} depth={depth + 1} mobile={mobile} close={close} />}

          </>}

      </div>
    );
  } else {
    return (
      <>
        <a
          href={item['@id']}
          key={item['@id']}
          className="item"
          rel="noopener noreferrer"
          target={settings.openExternalLinkInNewTab ? '_blank' : '_self'}
        >
          {item.title}
        </a>
        {isHovered && item.items && item.items.length > 0 && <SecondTierNavigation items={item.items} depth={depth + 1} />}
      </>
    );
  }
};

export default NavItem;
