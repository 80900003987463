import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink, UniversalLink } from '@plone/volto/components';
import pdfIcon from './PDF_icon.svg';
import FolderIcon from './Folder.svg';
import defaultIcon from './Link_icon.svg'; // Add a default icon for unknown types
import WordIcon from './Word.svg'; // Add a default icon for unknown types
import VideoIcon from './Video.svg'; // Add a default icon for unknown types

const iconMapping = (item) => {
  if (item['@type'] === "Document")
    return FolderIcon;
  if (item['@type'] === "File") {
    if (item.title && (item.title.toLowerCase().endsWith('.docx') || item.title.toLowerCase().endsWith('.doc'))) {
      return WordIcon; 
    }
    if (item.file?.filename && (item.file.filename.toLowerCase().endsWith('.mp4'))) {
      return VideoIcon; 
    } 
    else {
      return pdfIcon; 
    }
  }
  return defaultIcon;
};

const ListeOrdner = ({ items, isEditMode }) => {
  return (
    <>
      <div className="items">
        {items.map((item) => (

          <div className="listing-item" key={item['@id']}>
            <ConditionalLink item={item} condition={!isEditMode}>
              <div className="listing-body">
                <div className='listing-container'>
                  <img
                    src={iconMapping(item)}
                    alt={'Icon'}
                    className="listing-icon"
                  />
                  <h4>{item.title ? item.title : item.id}</h4>
                </div>
                <p>{item.description}</p>
              </div>
            </ConditionalLink>
          </div>
        ))}
      </div>
    </>
  );
};

ListeOrdner.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default ListeOrdner;
